/* set defaults for printer */
@page {
  margin: 0;
  scale: 100;
}

a {
  cursor: pointer;
  color: blue;
}

a[disabled] {
  pointer-events: none !important;
}

.ui.sidebar {
  overflow: visible !important;
}

#portal,
.fabric {
  height: 100vh !important;
}

.reactour__body #portal,
.reactour__body .fabric,
.reactour__body .pushable,
.reactour__body .pusher {
  min-height: 0px !important;
  height: 100% !important;
  overflow: auto !important;
}

